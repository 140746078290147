import { GraphQLResult } from "@/interface/common";

type TGraphqlFetcher = <T>(
  query: any,
  variables?: {},
) => Promise<GraphQLResult<T>>;

export const graphqlFetcher: TGraphqlFetcher = async (
  query,
  variables = {},
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const graphqlFetch = await fetch(
        process.env.NEXT_PUBLIC_GRAPHQL_API_URL || "",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.NEXT_PUBLIC_APPSYNC_KEY || "",
          },
          body: JSON.stringify({
            query,
            variables,
          })
        }
      );

      const graphqlResult = await graphqlFetch.json();

      return resolve(graphqlResult);
    } catch (error) {
      console.log("*** Error ***");
      console.log(error);

      return reject(JSON.stringify(error));
    }
  });
};

export const QueryKeys = {
  MENU: "MENU",

  //공통 사이트코드
  LIST_SITES: "LIST_SITES",

  // 패밀리게이트
  GET_FAMILYGATE: "GET_FAMILYGATE",

  //퀵메뉴
  QUICK_MENUS: "QUICK_MENUS",

  // 합격스펙
  LIST_PASS_SPECIFICATIONS: "LIST_PASS_SPECIFICATIONS",
  GET_PASS_SPECIFICATIONS: "GET_PASS_SPECIFICATIONS",

  //수강신청
  LIST_LECTURES: "LIST_LECTURES",
  LIST_SMART_FINDER_TEACHERS: "LIST_SMART_FINDER_TEACHERS",
  LATEST_LECTURES: "LATEST_LECTURES",
  LECTURE_DETAIL: "LECTURE_DETAIL",
  LECTURE_TEACHER_QUESTION: "LECTURE_TEACHER_QUESTION",

  // 선생님
  LIST_TEACHER: "LIST_TEACHER",
  TEACHER_BANNER: "TEACHER_BANNER",
  GET_TEACHER: "GET_TEACHER",
  GET_TEACHER_DETAIL: "GET_TEACHER_DETAIL",
  GET_ACADEMY_LECTURE: "GET_ACADEMY_LECTURE",

  // GNB
  LIST_MENUS: "LIST_MENUS",

  // 교재 무료자료
  LIST_FREE_CONTENTS: "LIST_FREE_CONTENTS",

  // 스마트 파인더
  LIST_SMART_FINDER: "LIST_SMART_FINDER",

  //무료강의
  LIST_FREELECTURE: "LIST_FREELECTURE",
  BEST_LIST_FREELECTURE: "BEST_LIST_FREELECTURE",
  LIST_DEPTH4: "LIST_DEPTH4",

  // 커뮤니티
  LIST_BOARD: "LIST_BOARD",
  GET_BOARD: "GET_BOARD",
  GET_VIEW_COUNT: "GET_VIEW_COUNT",

  // 공통코드
  GET_COMMON_CODE: "GET_COMMON_CODE",

  // 이벤트
  GET_SEARCH_EVENT: "GET_SEARCH_EVENT",
  GET_SEARCH_EVENT_CODE: "GET_SEARCH_EVENT_CODE",
  GET_CARDINAL_BENEFITS: "GET_CARDINAL_BENEFITS",
  GET_EVENT_LOGS_COUNT: "GET_EVENT_LOG_COUNT",

  // 합격자 명단
  GET_PASSERS_LIST: "GET_PASSERS_LIST",

  // 디데이
  DDAY: "DDAY",

  // 팝업 배너
  GET_SEARCH_POPUPBANNER: "GET_SEARCH_POPUPBANNER",

  // 풀서비스
  GET_FULLSERVICE_REVIEW: "GET_FULLSERVICE_REVIEW",
  GET_FULLSERVICE_REVIEW_LIKE: "GET_FULLSERVICE_REVIEW_LIKE",
  GET_FULLSERVICE_EXAM: "GET_FULLSERVICE_EXAM",
  GET_FULLSERVICE_USER: "GET_FULLSERVICE_USER",
  GET_FULLSERVICE_SURVEY: "GET_FULLSERVICE_SURVEY",
  GET_FULLSERVICE_SURVEYJOIN: "GET_FULLSERVICE_SURVEYJOIN",

  // 전광판
  GET_SERACH_DISPLAY_BOARD: "GET_SERACH_DISPLAY_BOARD",

  // 유저 결제 유무 조회
  GET_PAYMENT_DATA: "GET_PAYMENT_DATA",

  // 당일 결제 횟수 조회
  GET_PAYMENT_COUNT_DATA: "GET_PAYMENT_COUNT_DATA",

  // 유저 정보 조회
  GET_MEMBER_INFO: "GET_MEMBER_INFO",
};
