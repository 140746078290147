export const getLectureFront = /* GraphQL */ `
  query GetLectureDetail($id: ID!) {
    getLecture(id: $id) {
      id
      sitesID
      lectureName
      teacherName
      lectureImage
      lectureListPointText
      lectureDetailPointText
      shootingTime
      icons
      lectureLink
      averageLearningTime
      isDisplayCurriculum
      isDisplayTeacherQuestion
      isDisplayReview
      createdAt
      lectureAlerts {
        items {
          id
          isDisplay
          image
          content
          linkURL
        }
        nextToken
        startedAt
        __typename
      }
      lectureIntroduces {
        items {
          id
          lectureViewType
          lectureIntroduceImages
          lectureIntroduceText
          lectureIntroduceTextMobile
          teacherViewType
          teacherIntroduceImages
          teacherIntroduceText
          teacherIntroduceTextMobile
          bookViewType
          bookIntroduceImage
          bookIntroduceText
          bookIntroduceTextMobile
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

// 합격 스펙 리스트
export const listPassSpecifications = /* GraphQL */ `
  query listPassSpecifications(
    $filter: ModelPassSpecificationsFilterInput
    $limit: Int
  ) {
    listPassSpecifications(filter: $filter, limit: $limit) {
      items {
        companyLogo
        companyName
        companyType
        id
        sortValue
        createdAt
      }
    }
  }
`;

// 합격 스펙 상세
export const getPassSpecifications = /* GraphQL */ `
  query getPassSpecifications($id: ID!) {
    getPassSpecifications(id: $id) {
      companyName
      companyLogo
      PassInterviews {
        items {
          passDate
          employmentType
          supportJob
          content
          id
        }
      }
      PassPersonalStatements {
        items {
          passDate
          employmentType
          supportJob
          content
          id
        }
      }
      PassSpecificationsStatuses {
        items {
          passDate
          employmentType
          supportJob
          university
          courseCredit
          toeicScore
          toeicSpeakingGrade
          certificationCount
          isOverseasExperience
          externalActivitiesCount
          id
        }
      }
    }
  }
`;

// 교재 상세
export const listBookIntroduces = /* GraphQL */ `
  query ListBookIntroducesDetail(
    $filter: ModelBookIntroduceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookIntroduces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sitesID
        bookId
        content
        isIntroduceUse
        bookLecture
        withBuyBooks
        bookInfos {
          content
          mobileContent
          title
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const searchBookIntroduces = /* GraphQL */ `
  query searchBookIntroduces(
    $filter: SearchableBookIntroduceFilterInput
    $from: Int
    $limit: Int
  ) {
    searchBookIntroduces(filter: $filter, from: $from, limit: $limit) {
      items {
        id
        sitesID
        bookId
        content
        isIntroduceUse
        bookLecture
        withBuyBooks
        bookInfos {
          content
          mobileContent
          title
        }
      }
      total
    }
  }
`;

export const listFreeContentsBySortValue = /* GraphQL */ `
  query ListFreeContentsBySortValueDetail(
    $sitesID: ID!
    $sortValueCreatedAt: ModelFreeContentsBySitesCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFreeContentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFreeContentsBySortValue(
      sitesID: $sitesID
      sortValueCreatedAt: $sortValueCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookId
        isPublic
        videoLessonURL
        fieldLessonURL
        authentications {
          authenticationTypeCode
          authenticationValue
        }
        freeContentsItems {
          freeContentsIconCode
          authenticationTypeCode
          itemName
          introduceText
          alertText
          freeContentsItemTypeCode
          freeContentsItem
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchFreeContents = /* GraphQL */ `
  query SearchFreeContentsDetail(
    $filter: SearchableFreeContentsFilterInput
    $from: Int
    $limit: Int
  ) {
    searchFreeContents(filter: $filter, from: $from, limit: $limit) {
      items {
        id
        bookId
        bookImg
        bookName
        showLabelCode
        videoLessonURL
        difficultyCode
        fieldLessonURL
        purchaseURL
        authentications {
          authenticationTypeCode
          authenticationValue
        }
        freeContentsItems {
          authenticationTypeCode
          itemName
          introduceText
          alertText
          freeContentsIconCode
          freeContentsItem
          freeContentsItemTypeCode
        }
      }
      total
    }
  }
`;

// 스펙업퀴즈 탭 ID, title
export const listDailyQuizGroups = /* GraphQL */ `
  query ListDailyQuizGroups2(
    $filter: ModelDailyQuizGroupFilterInput
    $limit: Int
  ) {
    listDailyQuizGroups(filter: $filter, limit: $limit) {
      items {
        id
        title
      }
    }
  }
`;

// 스펙업퀴즈 리스트
export const listDailyQuizBases = /* GraphQL */ `
  query ListDailyQuizBasesSentences(
    $filter: ModelDailyQuizBaseFilterInput
    $limit: Int
  ) {
    listDailyQuizBases(filter: $filter, limit: $limit) {
      items {
        id
        title
        quizDate
        correctAnswerRate
        DailyQuizSentences {
          items {
            sentenceImage
            sentenceText
            sortValue
          }
        }
      }
    }
  }
`;

// 스펙업퀴즈 리스트
export const getDailyQuizBases = /* GraphQL */ `
  query ListDailyQuizBasesQuestions(
    $filter: ModelDailyQuizBaseFilterInput
    $limit: Int
  ) {
    listDailyQuizBases(filter: $filter, limit: $limit) {
      items {
        id
        title
        quizDate
        correctAnswerRate
        DailyQuizSentences {
          items {
            sentenceImage
            sentenceText
            sortValue
          }
        }
        DailyQuizQuestions {
          items {
            id
            questionNumber
            questionContent
            questions
            answer
            commentary
            relatedTerms
            correctAnswerRate
            DailyQuizQuestionAnswers {
              items {
                userAnswer
              }
            }
          }
        }
      }
    }
  }
`;

// 선생님 리스트
export const searchTeachers = /* GraphQL */ `
  query SearchTeachersList (
    $filter: SearchableTeacherFilterInput
    $sort: [SearchableTeacherSortInput]
  ) {
    searchTeachers(filter: $filter, sort: $sort) {
      items {
        academyLectures
        academyBestLectures
        academyCurriculums
        academyEvents
        academyRepresentingLectures
        teacherIdx
        teacherIntroduce
        teacherSubjectCategory
        titleTextTop
        titleTextSubject
        displayTeacherName
        teacherDetailImage
        teacherMobileDetailImage
        teacherCurriculumImage
        teacherIconImage
        reasonText
        representingVideoURL
        representingVideoThumbnail
        otVideoURL
        otVideoThumbnail
        teacherIntroduceDetailImage
        teacherHighScoreImage
        teacherStepImage
        bestLectures
        events
        lectures
        lectureReviewVideo {
          video
          thumbnail
        }
        bestLectureSample {
          title
          thumbnail
          url
        }
        displayTeacherName
        iconText
        description
        sortValue
        teacherListMainImage
        teacherListImageOn
        teacherListImageOff
        teacherIntroduce
      }
    }
  }
`;

// 선생님 상세
export const getTeacher = /* GraphQL */ `
  query GetTeacherDetail($id: ID!) {
    getTeacher(id: $id) {
      teacherIdx
      teacherIntroduce
      teacherSubjectCategory
      titleTextTop
      titleTextSubject
      displayTeacherName
      teacherDetailImage
      teacherMobileDetailImage
      teacherCurriculumImage
      teacherIconImage
      reasonText
      representingVideoURL
      representingVideoThumbnail
      otVideoURL
      otVideoThumbnail
      teacherIntroduceDetailImage
      teacherHighScoreImage
      teacherStepImage
      bestLectures
      events
      lectures
      lectureReviewVideo {
        video
        thumbnail
      }
      bestLectureSample {
        title
        thumbnail
        url
      }
    }
  }
`;

// 모의고사 정보
export const getMockExamBase = /* GraphQL */ `
  query GetMockExamBaseDetail($id: ID!, $memberIdx: Int) {
    getMockExamBase(id: $id) {
      MockExamQuestions {
        nextToken
        startedAt
        items {
          MockExamSubject {
            id
            memo
            subjectName
            typeQuestionName
            scoreComments {
              comment
              endScore
              evaluationStandard
              startScore
              typeQuestion
            }
          }
          answers {
            answer
            score
            questionNumber
            correctAnswerRate
          }
          commentaryPaper
          id
          mockExamQuestionMockExamSubjectId
          mockexambaseID
          optionCount
          pauseLimitMinute
          questionCount
          questionPaper
          solveLimitMinute
        }
      }
      MockExamUsers(
        filter: { _deleted: { ne: true }, memberIdx: { eq: $memberIdx } }
      ) {
        items {
          id
          affiliatesName
          memberId
          memberIdx
          memberName
          mockexambaseID
          MockExamUserQuestionAnswerTemps(filter: { _deleted: { ne: true } }) {
            items {
              mockExamUserQuestionAnswerTempMockExamQuestionId
              mockexamansweruserID
              remainQuestionTime
              id
              answers {
                answer
                questionNumber
              }
            }
          }
          MockExamUserQuestionAnswers(filter: { _deleted: { ne: true } }) {
            items {
              id
              mockExamUserQuestionAnswerMockExamQuestionId
              mockexamansweruserID
              score
              answers {
                answer
                isCorrect
                questionNumber
              }
              MockExamQuestion {
                MockExamSubject {
                  id
                  memo
                  scoreComments {
                    comment
                    endScore
                    evaluationStandard
                    startScore
                    typeQuestion
                  }
                  subjectName
                  typeQuestionName
                }
                answers {
                  answer
                  questionNumber
                  score
                  correctAnswerRate
                }
                id
                mockExamQuestionMockExamSubjectId
                mockexambaseID
                optionCount
                pauseLimitMinute
                questionCount
                questionPaper
                solveLimitMinute
              }
            }
          }
        }
      }
      MockExamCompany {
        id
        companyLogo
        companyName
        examSubjects
        affiliatesType
        affiliatesName
      }
      id
      mockexamcompanyID
      title
    }
  }
`;

// 게시판 리스트
export const searchBoards = /* GraphQL */ `
  query SearchBoardsDetail(
    $filter: SearchableBoardFilterInput
    $sort: [SearchableBoardSortInput]
    $from: Int
    $limit: Int
  ) {
    searchBoards(filter: $filter, sort: $sort, from: $from, limit: $limit) {
      items {
        id
        answer
        answerMemberId
        answerMemberIdx
        answerMemberName
        businessName
        businessPosition
        categoryCode
        content
        comments(filter: {_deleted: {ne: true}}) {
          items {
            id
            content
            regMemberId
          }
        }
        description
        files {
          fileName
          regDatetime
          size
          url
        }
        lectureIdx
        linkImage
        linkURL
        regMemberName
        regMemberIdx
        regMemberId
        sitesID
        teacherIdx
        thumbnail
        title
        typeCode
        videoURL
        isNotice
        isDisplay
        isBest
        productIdx
        bookId
        createdAt
        viewCount
        location
        updatedAt
        _version
      }
      total
    }
  }
`;

// 게시판 상세
export const getBoard = /* GraphQL */ `
  query GetBoardDetail($id: ID!) {
    getBoard(id: $id) {
      _version
      categoryCode
      lectureIdx
      teacherIdx
      title
      content
      files {
        fileName
        size
        url
        regDatetime
      }
      thumbnail
      videoURL
      linkImage
      linkURL
      viewCount
      answer
      answerMemberId
      answerMemberIdx
      answerMemberName
      regMemberId
      regMemberIdx
      regMemberName
      createdAt
      isNotice
      isBest
      isDisplay
      comments(filter: {_deleted: {ne: true}}, sortDirection: ASC) {
        items {
          id
          boardsID
          parentId
          content
          regMemberName
          regMemberIdx
          createdAt
          _version
        }
      }
    }
  }
`;


//지금 지원해야 할 기업

export const ListJobPostingMainsQuery = /* GraphQL */ `
  query ListJobPostingMainsSearch(
    $filter: ModelJobPostingMainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobPostingMains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sortValue
        createdAt
        updatedAt
        JobPosting {
          id
          jobPostingTitle
          jobPostingTitleSub1
          jobPostingTitleSub2
          jobPostingType
          companyLogo
          jobPostingBeginDate
          jobPostingEndDate
          isDisplayMain
          displayMainSortValue
          firstDisplayItemKey
          JobPostingItemsJson
        }
        _version
        _deleted
        _lastChangedAt
        jobPostingMainJobPostingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;


export const searchGetEvents = /* GraphQL */ `
  query SearchGetEvents(
    $filter: SearchableEventFilterInput
    $sort: [SearchableEventSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventAggregationInput]
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        mainCategoryClassCode
        subCategoryClassCode
        eventCode
        eventName
        eventURL
        ogImage
        ogKeyword
        ogDescription
        lectures
        quickBanner
        topBanner
        topLineBanner
        footerBanner
        footerLineBanner
        isFooterFix
        spreadImage
        eventVideos {
          videoNo
          videoTypeCode
          videoURL
          videoTitle
          isClickPlay
          thumbnail
        }
        eventBenefits {
          benefitNo
          benefitTypeCode
          benefitValue
          benefitTitle
        }
        scheduleImage
        lectureLists
        freepassLists {
          categoryName
          categoryItems {
            itemName
            items
          }
        }
        isUseTimer
        isTimerFixed
        eventCardinals {
          cardinalNumber
          cardinalBeginDate
          cardinalEndDate
        }
        eventInstructions {
          InstructionNumber
          InstructionTitle
          InstructionContent
        }
        eventAttributes {
          AttributeId
          AttributeName
          AttributeDescription
          __typename
          AttributeItems {
            itemKey
            itemValue
          }
        }
      }
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const searchEventLogs = /* GraphQL */ `
  query searchEventLogs(
    $filter: SearchableEventLogFilterInput
    $sort: [SearchableEventLogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventLogAggregationInput]
  ) {
    searchEventLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        eventCode
        memberIdx
        memberId
        userInfo
        key
        value
        createdAt
        updatedAt
        _version
        _lastChangedAt
      }
      total
    }
  }
`;

export const searchPopupBanners = /* GraphQL */ `
  query SearchPopupBannersCustom(
    $filter: SearchablePopupBannerFilterInput
    $sort: [SearchablePopupBannerSortInput]
    $limit: Int
  ) {
    searchPopupBanners(
      filter: $filter
      sort: $sort
      limit: $limit
    ) {
      items {
        id
        title
        bannerFunctionType
        isShake
        displayBeginDateTime
        displayEndDateTime
        displayLocationX
        displayLocationY
        isCalcRelative
        isAlignCenter
        exposureLocation
        viewIFrameURL
        viewImageURL
        bannerTargetCode
        linkURL
        isUse
        sortValue
        isDisplayPC
        isDisplayMobile
        mapLinks {
          coords
          linkAlt
          linkURL
          target
        }
      }
    }
  }
`;

export const searchFullServiceExamReview = /* GraphQL */ `
query CustomSearchFullServiceExamReviews (
  $filter: SearchableFullServiceExamReviewFilterInput
  $sort: [SearchableFullServiceExamReviewSortInput]
  $from: Int
  $limit: Int
) {
  searchFullServiceExamReviews(
    filter: $filter
    sort: $sort
    from: $from
    limit: $limit
  ) {
    items {
      content
      id
      isBest
      regMemberId
      regMemberIdx
      regMemberName
      likeCount
      sortValue
      _version
    }
    total
  }
}
`;

export const searchFullServiceExamReviewLike = /* GraphQL */ `
query CustomSearchFullServiceExamReviewLike(
  $filter: SearchableFullServiceExamReviewLikeFilterInput
) {
  searchFullServiceExamReviewLikes(
    filter: $filter
  ) {
   items {
    id
    fullServiceExamReviewID
    regMemberIdx
    _version
   }
  }
}
`;

export const getFullServiceExam = /* GraphQL */`
query CustomSearchFullServiceExam($id: ID!) {
  getFullServiceExam(id: $id) {
    id
    examTime
    examName
    examFile
    examDegree
    examDate
    FullServiceSubjectQuestions(filter: { _deleted: { ne: true }}) {
      items {
        id
        FullServiceSubject {
          id
          subjectName
        }
        answers {
          answer
          correctAnswerRate
          questionNumber
          score
        }
      }
    }
    FullServiceExplains(filter: { _deleted: { ne: true }}) {
      items {
        id
        teacherName
        teacherImage
        subjectPC
        explainFile
        createdAt
      }
    }
  }
}
`;

export const getFullServiceUser = /* GraphQL */`
query CustomGetFullServiceUser(
  $filter: SearchableFullServiceUserFilterInput
  $sort: [SearchableFullServiceUserSortInput]
  $limit: Int
) {
  searchFullServiceUsers(
    filter: $filter
    sort: $sort
    limit: $limit
  ) {
    items {
      userName
      userId
      userIdx
      fullServiceExamID
      id
      _version
      testNumber
      FullServiceUserAnswers {
        items {
          id
          _version
          FullServiceSubjectQuestion {
            answers {
              score
              answer
            }
            FullServiceSubject {
              subjectName
              id
            }
          }
          answers {
            questionNumber
            answer
          }
        }
      }
    }
  }
}
`;

export const getFullServiceSurveys = /* GraphQL */`
query CustomGetFullServiceSurveys(
  $filter: SearchableFullServiceSurveyFilterInput
  $sort: [SearchableFullServiceSurveySortInput]
) {
  searchFullServiceSurveys(
    filter: $filter
    sort: $sort
  ) {
    items {
      survey
      userName
      userIdx
      userId
    }
    total
  }
}
`;

export const searchFullServiceDisplayBoards = /* GraphQL */`
query CustomSearchFullServiceDisplayBoards(
  $filter: SearchableFullServiceDisplayBoardFilterInput
  $sort: [SearchableFullServiceDisplayBoardSortInput]
) {
  searchFullServiceDisplayBoards(filter: $filter sort: $sort) {
    items {
      id
      displayBoardCode
      content
    }
  }
}
`;


export const getEventLogsCount = /* GraphQL */`
query GetEventLogsCount(
  $filter: SearchableEventLogFilterInput
) {
  searchEventLogs(filter: $filter) {
    total
  }
}
`;

export const searchEventCardinalBenefits = /* GraphQL */ `
  query CustomSearchEventCardinalBenefits(
  $filter: SearchableEventCardinalBenefitFilterInput
) {
  searchEventCardinalBenefits(
    filter: $filter
  ) {
    items {
      id
      eventId
      eventCode
      benefitMemberCount
      benefitCycle
      Lastbenefit
      benefitCardinalResults {
        benefitMembers
        cardinalNumber
        cardinalBeginDate
        cardinalEndDate
      }
      benefitItems {
        benefitType
        benefitCode
        benefitName
      }
    }
  }
}
`;