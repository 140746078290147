import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

class ApiClient {
  api: AxiosInstance;
  option: {
    contentType: string;
    baseUrl: string;
    serviceid?: string;
  } & AxiosRequestConfig;

  constructor(
    _option?: {
      contentType: string;
      baseUrl: string;
      serviceid?: string;
    } & AxiosRequestConfig,
    timeout = 30000,
  ) {
    this.option = _option || {
      contentType: "application/json; charset=utf-8",
      baseUrl: "/v1",
    };

    this.api = Axios.create({
      baseURL: this.option.baseUrl,
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": this.option.contentType,
        "Access-Control-Allow-Origin": "*",
        "x-api-key": process.env.NEXT_PUBLIC_GATEWAY_KEY || "",
        "serviceid": this.option.serviceid || "3065",
        ...this.option.headers,
      },
      params: this.option.params,
      timeout: timeout,
    });

    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log("error:", error);
        return error;
      },
    );
  }

  get<T = any, R = AxiosResponse<T>>(url: string, params?: any): Promise<R> {
    console.log("API get:", url, params);
    return this.api.get(url, params);
  }
  delete<T = any, R = AxiosResponse<T>>(url: string, params?: any): Promise<R> {
    console.log("API delete:", url);
    return this.api.delete(url, params);
  }
  post<T = any, R = AxiosResponse<T>>(url: string, data?: any): Promise<R> {
    console.log("API post:", url);
    data && console.log("body:", data);
    return this.api.post(url, data);
  }
  put<T = any, R = AxiosResponse<T>>(url: string, data?: any): Promise<R> {
    console.log("API put:", url);
    data && console.log("body:", data);
    return this.api.put(url, data);
  }
  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any): Promise<R> {
    console.log("API patch:", url);
    data && console.log("body:", data);
    return this.api.patch(url, data);
  }
}

export default ApiClient;
